
import $ from "jquery";
global.$ = $
global.jQuery = $

import regula from 'custom/regula-1.3.4';
global.regula = regula
require("custom/jquery.regula")

import("jqueryui");
import("jquery-ui/ui/core");
import("jquery-ui/ui/widget");
import("jquery-ui/ui/widgets/autocomplete");
import("jquery-ui/ui/position");

window.jQuery = $;
window.$ = $;

import 'easy-autocomplete/dist/jquery.easy-autocomplete';

import "@fortawesome/fontawesome-free/js/all";

import("bootstrap");

require("flatpickr");

import dt from "datatables.net";
import 'datatables.net-dt';

require("custom/service");
require("jquery.tools");
require.context('../images', true);
