initCreateOrderJs = function(targetsUrl){
    $("#change_mode").click(function(event){
      var nameFromVal = $("#service_namefrom").val(),
      customerNameFromVal = $("#customer_service_namefrom").val(),
      labelFromVal = $("#label_namefrom").val(),
      streetFromVal = $("#service_streetfrom").val(),
      zipFromVal = $("#service_zipfrom").val(),
      cityFromVal = $("#service_cityfrom").val(),
      contactFromVal = $("#service_contact_from").val(),
      phoneFromVal = $("#service_phone_from").val();

      var nameToVal = $("#service_nameto").val(),
      customerNameToVal = $("#customer_service_nameto").val(),
      labelToVal = $("#label_nameto").val(),
      streetToVal = $("#service_streetto").val(),
      zipToVal = $("#service_zipto").val(),
      cityToVal = $("#service_cityto").val(),
      contactToVal = $("#service_contact_to").val(),
      phoneToVal = $("#service_phone_to").val();

      $("#service_nameto").val(nameFromVal);
      $("#customer_service_nameto").val(customerNameFromVal);
      $("#label_nameto").val(labelFromVal);
      $("#service_streetto").val(streetFromVal);
      $("#service_zipto").val(zipFromVal);
      $("#service_cityto").val(cityFromVal);
      $("#service_contact_to").val(contactFromVal);
      $("#service_phone_to").val(phoneFromVal);

      $("#service_namefrom").val(nameToVal);
      $("#customer_service_namefrom").val(customerNameToVal);
      $("#label_namefrom").val(labelToVal);
      $("#service_streetfrom").val(streetToVal);
      $("#service_zipfrom").val(zipToVal);
      $("#service_cityfrom").val(cityToVal);
      $("#service_contact_from").val(contactToVal);
      $("#service_phone_from").val(phoneToVal);
      event.preventDefault();
    })

    $(".type-of-service").click(function(){
      var self = this;
      $(".type-of-service").each(function(index,checkbox){
        if(self != checkbox){
          checkbox.checked = false;
        }
      })
    })

}
